import moment from "moment";
import { RideInfoModel } from "../../../../../models/ride.info";
import React, { useEffect } from "react";
import { FaMapMarker, FaMapPin } from "react-icons/fa";

type Props = {
    info: RideInfoModel
}

const RideInfoSummary: React.FC<Props> = ({ info }) => {
    return (
        <div className="d-flex flex-row pt-3 pb-3">
            <div className="col-md-6 px-3">
                <h5>{moment(`${info.pickupDate} ${info.pickupTime}`).format("DD/MM/yyyy HH:mm")}</h5>
                <div>Passenger: {info.passengers}</div>
            </div>
            <div className="col-md-6 px-3">
                <div>
                    <FaMapPin color="black"/>
                    {info.pickupPlace.formatted_address}
                </div>
                <div>
                    <FaMapPin color="black"/>
                    {info.dropOffPlace.formatted_address}
                </div>
            </div>
        </div>
    )
}
export default RideInfoSummary;