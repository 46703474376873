import { FaRegCircle, FaRegEdit } from "react-icons/fa";

type Props = {
    title: string;
    showEditing: boolean
    onEdit: () => void;
}

const SectionHeader: React.FC<Props> = ({ title, showEditing, onEdit }) => {
    return (
        <div 
            className="px-3 pb-2 pt-3 col-md-12" 
            style={{ 
                backgroundColor: "#e2e2e2", 
                color: "#b00000"
            }}
        >
            <div className="d-flex flex-row">
                <div className="d-flex flex-row col-md-10">
                    <FaRegCircle color="#b00000" />&nbsp;&nbsp;
                    <h4 style={{ fontSize: "14px" }}>{title}</h4>
                </div>
                {showEditing && <div className="d-flex flex-row justify-content-end col-md-2">
                    <a href="#" onClick={onEdit}>
                        <FaRegEdit className="mb-1" strokeWidth={1} size={20} color="gray" />
                    </a>
                </div>}
            </div>
        </div>
    )
}

export default SectionHeader;