import React, { useEffect } from "react";
import Step2 from "./tabs/step2";
import Step1 from "./tabs/step1";
import { useDispatch, useSelector } from "react-redux";
import { backToStep1 } from "../../redux/reducers";

type Props = {}

const MainPage: React.FC<Props> = ({}) => {
    const dispatch = useDispatch();
    const currentStep = useSelector((state: any) => state.step);
    const rideInfo = useSelector((state: any) => state.rideInfo);

    return (
        <div>
            <Step1 
                currentStep={currentStep} 
                data={rideInfo} 
                onEdit={() => dispatch(backToStep1())}
            />
            <Step2 currentStep={currentStep} data={rideInfo} />
        </div>
    );
}

export default MainPage;