import { Button, Card } from "react-bootstrap";
import { Vehicle } from "../../../../../models/vehicle"

type Props = {
    vehicle: Vehicle;
}
const VehicleCard: React.FC<Props> = ({ vehicle }) => {
    return (
        <Card style={{ minHeight: "350px"}}>
            <Card.Body className="text-center">
                <Card.Title style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{vehicle.name}</Card.Title>
                <div style={{  }}>
                    <div className="position-relative">
                        <img style={{ maxWidth: "100%" }} src={vehicle.imageUrl} />
                    </div>
                    <div style={{ width: "100%", position: "absolute", left: 0, bottom: 0 }} className="px-2 py-2">
                        <Button style={{ borderRadius: '0', borderColor: "#b00000" }} className="btn btn-light col-md-12 mb-1">
                            <div style={{ color: "#b00000"}}>Request Quote</div>
                        </Button>
                        <Button style={{ borderRadius: '0', backgroundColor: "#b00000", color: "white" }} className="btn btn-light col-md-12">
                            Book
                        </Button>
                    </div>
                </div>
            </Card.Body>
        </Card>

    )
}

export default VehicleCard;