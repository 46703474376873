import { createSlice } from "@reduxjs/toolkit";
import { RideInfoModel } from "../models/ride.info";

type State = {
    step: number;
    rideInfo?: RideInfoModel
}

const initialState: State = { step: 1 }

const StepSlice = createSlice({
    name: "steps",
    initialState,
    reducers: {
        moveToStep2: (state, action) => {
            console.log(action.payload);
            state.step = 2;
            state.rideInfo = action.payload;
        },
        moveToStep3: (state) => {
            state.step = 3;
        },
        backToStep1: (state) => {
            state.step = 1;
        }
    }
});

export default StepSlice.reducer;
export const { moveToStep2, moveToStep3, backToStep1 } = StepSlice.actions;