import { vehicleList } from "../../../../models/vehicle";
import SectionHeader from "../../components/section.header";
import VehicleList from "./components/vehicle.list";

type Props = {
    currentStep: number;
    data: {

    }
}

const Step2Tab: React.FC<Props> = ({ currentStep }) => {
    return (
        <div>
            <SectionHeader 
                title="Step 2: Select Vehicle" 
                showEditing={currentStep > 2} 
                onEdit={() => null}
            />
            {currentStep === 2 && <VehicleList vehicles={vehicleList} didSelectVehicle={() => null} />}
        </div>
    )
}
export default Step2Tab;