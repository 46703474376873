import { Col, Row } from "react-bootstrap";
import { Vehicle } from "../../../../../models/vehicle"
import VehicleCard from "./vehicle.card";

type Props = {
    vehicles: Array<Vehicle>
    didSelectVehicle: (vechicle: Vehicle) => void;
}

const VehicleList: React.FC<Props> = ({ vehicles, didSelectVehicle }) => {

    return (
        <div className="px-3 py-3">
            <Row xs={1} md={2} lg={4}>
                {vehicles.map(vehicle => (
                    <Col className="mb-3" key={vehicle.id}>
                        <VehicleCard vehicle={vehicle} />
                    </Col>
                ))}
            </Row>
        </div>
    );
}
export default VehicleList;