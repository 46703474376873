import SectionHeader from "../../../components/section.header"
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import { FaUsers, FaShoppingBasket, FaTrash } from "react-icons/fa";
import { GoogleMap, Libraries, LoadScript, Marker, PolylineF, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { LatLng, LatLngTuple, decode } from "@googlemaps/polyline-codec";
import { pickupIcon, dropoffIcon, waypointIcon } from "../../../../../svgs";
import { useDispatch } from "react-redux";
import { moveToStep2 } from "../../../../../redux/reducers";
import moment from "moment";

const LIBRARIES: Libraries = ["places", "routes", "drawing"]

type Props = {

}

const RideInfo: React.FC<Props> = ({ }) => {
    const [serviceType, setServiceType] = useState<string | undefined>(undefined);
    const [pickupDate, setPickupDate] = useState(moment(new Date()).format("yyyy-MM-DD"));
    const [pickupTime, setPickupTime] = useState(moment(new Date()).format("yyyy-MM-DD HH:mm"));
    const [passengers, setPassengers] = useState(1);
    const [luggageCount, setLuggageCount] = useState(0);
    const [pickupPlace, setPickupPlace] = useState<google.maps.places.PlaceResult>();
    const [dropOffPlace, setDropOffPlace] = useState<google.maps.places.PlaceResult>();
    const [stops, setStops] = useState<Array<google.maps.places.PlaceResult | null>>([]);
    const [paths, setPaths] = useState<Array<LatLng[]>>([]);
    const [center, setCenter] = useState<google.maps.LatLng | null>(null);
    const [estimatedDistance, setEstimatedDistance] = useState(0);
    const pickupLocationRef = useRef(null);
    const dropoffLocationRef = useRef(null);
    const stopLocationsRef = useRef<Array<StandaloneSearchBox | null>>([]);
    const mapRef = useRef<google.maps.Map | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (isLoaded && pickupPlace && dropOffPlace) {
            const directionsService = new google.maps.DirectionsService();
            directionsService.route({
                optimizeWaypoints: true,
                waypoints: stops.map(stop => ({ location: stop?.geometry?.location, stopover: false })),
                travelMode: google.maps.TravelMode.DRIVING,
                origin: { location: pickupPlace?.geometry?.location },
                destination: { location: dropOffPlace?.geometry?.location },
            }, directionsCallback);
        }
    }, [isLoaded, pickupPlace, dropOffPlace, stops]);

    const directionsCallback = (response: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
        if (status === google.maps.DirectionsStatus.OK) {
            const route = response?.routes[0];
            const legs = route?.legs;
            const steps: Array<LatLngTuple[]> = [];
            const paths: Array<LatLng[]> = [];
            let distance = 0;
            legs?.forEach(leg => {
                distance += leg.distance?.value ?? 0;
                const legSteps = leg.steps;
                legSteps.forEach(step => {
                    const decodedPolyline = decode(step?.encoded_lat_lngs);
                    steps.push(decodedPolyline);
                });
            });
            setEstimatedDistance(distance);
            steps.map(step => {
                const path: LatLng[] = []
                step.map(s => {
                    path.push({ lat: s[0], lng: s[1] })
                });
                paths.push(path);
            });
            setPaths(paths);
        }
    };

    useEffect(() => {
        mapRef.current?.fitBounds(getMapCenter(paths))
    }, [paths]);
    
    const handlePickupSearchBoxLoad = (ref: any) => {
        pickupLocationRef.current = ref;
    }

    const handleDropOffSearchBoxLoad = (ref: any) => {
        dropoffLocationRef.current = ref;
    }

    const handleStopSearchBoxLoad = (ref: any, index: number) => {
        stopLocationsRef.current[index] = ref;
    }

    const handlePickupSelect = () => {
        // @ts-ignore
        const places = pickupLocationRef?.current?.getPlaces();
        if (places && places.length > 0) {
            console.log("place:", places[0]);
            setPickupPlace(places[0]);
        }
    }

    const handleDropOffSelect = () => {
        // @ts-ignore
        const places = dropoffLocationRef?.current?.getPlaces();
        if (places && places.length > 0) {
            setDropOffPlace(places[0]);
        }
    }

    const handleStopSelect = (index: number) => {
        // @ts-ignore
        const places = stopLocationsRef?.current[index]?.getPlaces();
        if (places && places.length > 0) {
            const _stops = [...stops];
            _stops[index] = places[0];
            setStops(_stops);
        }
    }

    const getSubLocality = (place: any) => {
        if (!place) return;
        if (!place.address_components) return;
        return place.address_components.find((comp: any) => comp.types.find((type: any) => type === "sublocality_level_1"))?.long_name;
    }

    const getMapCenter = (path: Array<LatLng[]>): google.maps.LatLngBounds => {
        const bounds = new google.maps.LatLngBounds();
        path.map(p => new google.maps.MVCArray(p)).map(f => bounds.extend(f.getAt(0)));
        return bounds;
    }

    const addStop = () => {
        stopLocationsRef.current.push(null);
        setStops([...stops, null]);
    }

    const removeStopAt = (index: number ) => {
        setStops(stops.filter((s, i) => i !== index));
    }

    return (
        <Form>
            <LoadScript onLoad={() => setIsLoaded(true)} googleMapsApiKey="AIzaSyCfPguWgs9oGb1ZPCw_4qgEt-dt95rVvCQ" libraries={LIBRARIES}>
                <div className="d-flex flex-row pt-3">
                    <div className="col-md-6 px-3 pb-3">
                        <div className="form-group pb-3">
                            <label htmlFor="serviceType" className="text-sm-left">Select Service Type</label>
                            <select id="serviceType" value={serviceType} onChange={(e) => setServiceType(e.target.value)} className="form-control">
                                <option value="Option 1">Point-to-Point</option>
                                <option value="Option 2">To Airport</option>
                                <option value="Option 3">DOMESTIC Airport Arrival</option>
                                <option value="Option 4">INTL AIRPORT ARRIVAL</option>
                                <option value="Option 5">Hourly/As Directed</option>
                                <option value="Option 6">Special/Sporting Event</option>
                                <option value="Option 7">Package Delivery</option>
                            </select>
                        </div>
                        <div className="d-flex flex-row pb-3">
                            <div className="form-group col-md-8">
                                <label>Pick-Up Date</label>
                                <input type="date" value={pickupDate?.toString()} onChange={(e) => setPickupDate(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group col-md-4 ps-2">
                                <label>Pick-Up Time</label>
                                <input type="time" value={pickupTime?.toString()} onChange={(e) => setPickupTime(e.target.value)} className="form-control" />
                            </div>
                        </div>
                        <div className="form-group pb-3">
                            <label className="col-md-6">Pick-Up Location</label>
                            <StandaloneSearchBox onLoad={handlePickupSearchBoxLoad} onPlacesChanged={handlePickupSelect}>
                                <Form.Control type="text" placeholder="Your pick-up location" />
                            </StandaloneSearchBox>
                            <div style={{ textAlign: "right" }} className="text-end">
                                {getSubLocality(pickupPlace)}
                            </div>
                        </div>
                        <div className="px-3 pb-3">
                            <Button onClick={addStop} style={{ color: "#b00000" }} className="btn btn-link">+ Add Stop</Button>
                            {stops.map((stop, index) => (
                                <div className="d-flex flex-row form-group pb-3">
                                    <div className="col-md-10">
                                        <StandaloneSearchBox 
                                            onLoad={(ref) => handleStopSearchBoxLoad(ref, index)} 
                                            onPlacesChanged={() => handleStopSelect(index)}
                                        >
                                            <input type="text" placeholder="Add your stop" className="form-control" />
                                        </StandaloneSearchBox>
                                    </div>
                                    <div className="col-md-2 px-2 pt-1">
                                        <a style={{ cursor: "pointer" }} onClick={() => removeStopAt(index)} className="">
                                            <FaTrash color="gray" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="form-group pb-3">
                            <label>Drop-Off Location</label>
                            <StandaloneSearchBox onLoad={handleDropOffSearchBoxLoad} onPlacesChanged={handleDropOffSelect}>
                                <input type="text" placeholder="Your drop-off location" className="form-control" />
                            </StandaloneSearchBox>
                            <div style={{ textAlign: "right" }} className="text-end">
                                {getSubLocality(dropOffPlace)}
                            </div>
                        </div>
                        <div className="d-flex flex-row pb-3">
                            <div className="form-group col-md-6 pe-2">
                                <label>Number of Passengers</label>
                                <div className="input-group">
                                    <InputGroup.Text style={{ borderRadius: '0' }}>
                                        <FaUsers />
                                    </InputGroup.Text>
                                    <Button variant="outline-secondary" onClick={() => passengers > 1 && setPassengers(passengers - 1)}>-</Button>
                                    <Form.Control min={1} max={10} value={passengers} style={{ textAlign: "center" }} type="number" onChange={(e) => setPassengers(parseInt(e.currentTarget.value))} />
                                    <Button style={{ borderRadius: '0' }} variant="outline-secondary" onClick={() => passengers < 10 && setPassengers(passengers + 1)}>+</Button>
                                </div>
                            </div>
                            <div className="form-group col-md-6 ps-2">
                                <label>Luggage Count</label>
                                <div className="input-group">
                                    <InputGroup.Text style={{ borderRadius: '0' }}>
                                        <FaShoppingBasket />
                                    </InputGroup.Text>
                                    <Button variant="outline-secondary" onClick={() => luggageCount > 0 && setLuggageCount(luggageCount - 1)}>-</Button>
                                    <Form.Control min={0} max={10} value={luggageCount} style={{ textAlign: "center" }} type="number" onChange={(e) => setLuggageCount(parseInt(e.currentTarget.value))} />
                                    <Button style={{ borderRadius: '0' }} variant="outline-secondary" onClick={() => luggageCount < 10 && setLuggageCount(luggageCount + 1)}>+</Button>
                                </div>
                            </div>
                        </div>
                        <Button 
                            className="btn btn-light col-md-6"
                            style={{ 
                                borderRadius: '0', 
                                backgroundColor: "#b00000", 
                                color: "white" 
                            }}
                            onClick={() => dispatch(
                                moveToStep2({ 
                                    serviceType,
                                    pickupDate,
                                    pickupTime,
                                    pickupPlace,
                                    dropOffPlace,
                                    passengers,
                                    luggageCount
                                })
                            )}
                        >
                            Select Vehicle
                        </Button>
                    </div>
                    <div className="col-md-6 px-3">
                        <GoogleMap
                            mapContainerStyle={{ width: "100%", height: "400px" }}
                            zoom={13}
                            center={center?.toJSON()}
                            // @ts-ignore
                            onLoad={(map => mapRef.current = map)}
                        >
                            {pickupPlace && <Marker key={"pickup"} options={{ icon: { url: pickupIcon }}} position={{ lat: pickupPlace.geometry?.location?.lat() ?? -34.397, lng: pickupPlace.geometry?.location?.lng() ?? 150.644 }} />}
                            {dropOffPlace && <Marker key={"drop-off"} options={{ icon: { url: dropoffIcon }}} position={{ lat: dropOffPlace.geometry?.location?.lat() ?? -34.397, lng: dropOffPlace.geometry?.location?.lng() ?? 150.644 }} />}
                            {stops.map((stop, index) => (
                                <Marker 
                                    key={`stop?${index}`} 
                                    options={{ 
                                        icon: { 
                                            url: waypointIcon 
                                        },
                                    }}
                                    position={{ 
                                        lat: stop?.geometry?.location?.lat() ?? 0, 
                                        lng: stop?.geometry?.location?.lng() ?? 0 
                                    }}
                                />
                            ))}
                            {paths.map((p, index) => (
                                <PolylineF key={index} path={p} options={{ strokeColor: "green", strokeOpacity: 0.5, strokeWeight: 5 }} />
                            ))}
                        </GoogleMap>
                        <div>{estimatedDistance > 0 && `Estimated distance: ${(estimatedDistance/1000).toFixed(2)} km`}</div>
                    </div>
                </div>
            </LoadScript>
        </Form>
    );
}
export default RideInfo;