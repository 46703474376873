export interface Vehicle {
    id: string;
    name: string;
    imageUrl: string;
    passengers: number;
    luggage: number;
}

export const vehicleList: Array<Vehicle> = [
    { 
        id: "1", 
        name: "Sedan", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/90094/5302018232418_jNEq27Sgg0Id4HWb37fQ.jpg",
        passengers: 4,
        luggage: 3
    }, 
    {
        id: "2", 
        name: "First Class Luxury Sedan", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/79352/8720172023_b17Hyl3XXs8Ud85sbmVI.jpg",
        passengers: 4,
        luggage: 3
    },
    {
        id: "3", 
        name: "EV / HYBRID", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/119312/672022223217_xCSf73Huu5WraV4p83tF.png",
        passengers: 4,
        luggage: 4
    },
    {
        id: "4", 
        name: "6 Seater Luxury People Mover MPV", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/79580/7220192004_m52Tkw7JJe4Go30emxIT.png",
        passengers: 6,
        luggage: 5
    },
    {
        id: "5", 
        name: "Business Class Luxury Sedan", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/79355/72201919549_lp26JwvIIDkGNkyDmx26.png",
        passengers: 4,
        luggage: 5
    },
    {
        id: "6", 
        name: "10 Seater Luxury People Mover", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/79581/8720170159_wcs1VIh77Ov6YvlOXJ61.jpg",
        passengers: 10,
        luggage: 12
    },
    {
        id: "7", 
        name: "SUV", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/79353/72201920191_aEUh8LJw86Y70XN60Lvh.jpg",
        passengers: 6,
        luggage: 3
    },
    {
        id: "8", 
        name: "Ferrari Purosangue", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/143411/12262023194141_ine5Hts22BhDKhwAJu15.jpg",
        passengers: 3,
        luggage: 1
    },
    {
        id: "9", 
        name: "Rolls Royce Ghost", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/80952/19201831917_afv2Ylk88SyUCyoRBm73.jpg",
        passengers: 4,
        luggage: 3
    },
    {
        id: "10", 
        name: "14 Seater Luxury Mini Coach", 
        imageUrl: "https://manage.mylimobiz.com/Uploads/12175/VehicleTypes/79582/8720170212_j4EQh76ggb2dl28b37FQ.jpg",
        passengers: 14,
        luggage: 15
    },
]