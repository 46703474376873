import { RideInfoModel } from "../../../../models/ride.info";
import SectionHeader from "../../components/section.header"
import RideInfo from "./components/ride.info"
import RideInfoSummary from "./components/ride.info.summary";

type Props = {
    currentStep: number;
    data?: RideInfoModel
    onEdit: () => any;
}

const Step1Tab: React.FC<Props> = ({ currentStep , data, onEdit}) => {
    return (
        <div>
            <SectionHeader 
                title="Step 1: Ride Info" 
                showEditing={currentStep > 1} 
                onEdit={onEdit}
            />
            {currentStep === 1 && <RideInfo />}
            {currentStep !== 1 && data && <RideInfoSummary info={data} />}
        </div>
    )
}
export default Step1Tab;